import $ from 'n-zepto'
import useSDK from '../data/useSDK'





const useAPI = () => {


    const { getToken, getVer, getPF } = useSDK()


    // const setToken = (token) => {
    //     window.localStorage.setItem("_token", token)
    // }
    // const getToken = () => {
    //     return window.localStorage.getItem("_token")

    // }


    const request = async (url, params, { } = {}) => {
        if (url?.startsWith("http") === false) url = process.env?.REACT_APP_DOMAIN + url;
// console.log('getToken()',getToken())
        return await new Promise((resolve, reject) => {
            $.ajax(
                {
                    url, data: { token: getToken(), app_ver: getVer(), pf: getPF(), ...params },
                    type: "get",
                    dataType: 'json',
                    success: (rs) => {
                        resolve(rs)
                    }, error: rs => {
                        reject(rs)
                    }
                }
            )
        });




    }

    return { request }

}

export default useAPI;