
const isDev = process.env.REACT_APP_IS_DEV === "1" 
export default () => {


    const getToken = () => {
        // return getTokenRaw()
        if (isDev) return window.localStorage.getItem("_token")
        return window?.gameBridge?.getToken?.()
    }

    const getAppid = () => {
        // return window.localStorage.getItem("app_id")
        if (isDev) return window.localStorage.getItem("app_id")
        return window?.gameBridge?.getAppid?.();
    }

    const openUrl = (url) => {
        // window.location.href = url;
        if (isDev) return window.location.href = url;
        window?.gameBridge?.openUrl?.(url);

    }

    const setToken = (token) => {
        if (isDev) return window.localStorage.setItem("_token", token)
    }
    const setAppid = (app_id) => {
        if (isDev) return window.localStorage.setItem("app_id", app_id)
    }

    const exit = () => {
        window?.gameBridge?.exit?.();
    }
    const canExit = () => {
        return window?.gameBridge?.exit !== undefined;
    }

    const getVer = () => {
        return window?.gameBridge?.getVer ? window?.gameBridge?.getVer() : "1";
    }

    const getPF = () => {
        return window?.gameBridge?.getPF ? window?.gameBridge?.getPF() : "";
    }



    return { getToken, getAppid, openUrl, setToken, setAppid, exit, canExit,getVer,getPF }




}